<script lang="ts">
  import { derived, Writable, writable } from "svelte/store";
  import { QuestionAccuracyRow } from "./QuestionAccuracyRow.js";
  import {
    filterReportData,
    ReportData,
    ReportDataFilter,
    ResultSet,
  } from "../report-data/index.js";
  import { sortTruthy, toTruthy } from "../dao/index.js";
  import {
    addSortBy,
    addSubRows,
    addExpandedRows,
    addFlatten,
    addTableFilter,
    addHiddenColumns,
  } from "svelte-headless-table/plugins";
  import {
    createTable,
    createRender,
    DataBodyRow,
  } from "svelte-headless-table";
  import TopicCell from "./TopicCell.svelte";
  import flatten from "lodash/flatten";
  import { MagnifyingGlass, Table } from "../reteyn-components/index.js";
  import { toFractionValue } from "./toFractionValue.js";
  import { toPercentageString } from "./toPercentageString.js";
  import { QuestionTableState } from "../report-data/index.js";
  import { createEventDispatcher } from "svelte";
  import ReportDataFilterEditor from "./ReportDataFilterEditor.svelte";
  import { missingValue } from "./missingValue.js";
  import { QuestionTableColumn } from "./QuestionTableColumn.js";
  import ShowAccuracyControl from "./ShowAccuracyControl.svelte";

  export let reportData: ReportData;
  export let rows: Writable<object[]>;
  export let numberFormatter: Intl.NumberFormat;
  export let state: QuestionTableState;
  let dataFilter: Writable<ReportDataFilter>;
  let data: ReportData;

  const showTopics: Writable<boolean> = writable();
  const showAccuracy: Writable<boolean> = writable();
  const showAllQuestions: Writable<boolean> = writable();

  const dispatch = createEventDispatcher<{ update: QuestionTableState }>();
  const expandedQuestions: Writable<string[]> = writable();
  const singleUserSymbols = [`❌`, `✅`];

  function toResultSet(row: QuestionAccuracyRow): ResultSet {
    return new ResultSet(
      row.tests,
      row.question
        ? [row.question]
        : toTruthy(
            data.reteyner.topics?.find((t) => t?.id === row.id)?.questions ||
              [],
          ),
      data.students,
    );
  }

  const dataStore = writable<QuestionAccuracyRow[]>([]);
  const table = createTable(dataStore, {
    sub: addSubRows({
      children: (row: QuestionAccuracyRow) => row.children,
    }),
    hide: addHiddenColumns(),
    flatten: addFlatten(),
    sort: addSortBy(),
    expand: addExpandedRows({}),
    tableFilter: addTableFilter({
      fn: (request: { filterValue: string; value: string | number }) =>
        (request.value + "")
          .toLowerCase()
          .includes(request.filterValue.toLowerCase()),
    }),
    topicStripes: () => ({
      pluginState: {},
      hooks: {
        "tbody.tr": (row) => ({
          attrs: derived(row.attrs(), (attrs) => ({
            ...attrs,
            class: row.depth ? "" : "font-bold bg-base-100",
          })),
        }),
        "tbody.tr.td": (cell) => ({
          attrs: derived(cell.attrs(), (attrs) => ({
            ...attrs,
            class: "align-top",
          })),
        }),
      },
    }),
  });
  const columns = table.createColumns([
    table.column({
      id: "question",
      header: derived(showTopics, (v) => (v ? "Topic" : "Question")),
      accessor: (row) => row.text,
      cell: (d, { pluginStates }) =>
        createRender(TopicCell, {
          ...pluginStates.expand.getRowState(d.row),
          expandedQuestions,
          showPercentage: data.students.length !== 1,
          grouped: $showTopics,
          numberFormatter,
          row: (d.row as DataBodyRow<QuestionAccuracyRow>).original,
          learners: data.students.length,
          submissions: toResultSet(
            (d.row as DataBodyRow<QuestionAccuracyRow>).original,
          ).toSubmissions({ latest: true }),
        }),
      plugins: {
        sort: {
          disable: true,
        },
      },
    }),

    table.column({
      id: QuestionTableColumn.Correct,
      header: "Correct",
      accessor: (row) => {
        const result = toResultSet(row);
        const submissions = result.toSubmissions({});
        return submissions.length
          ? toFractionValue(result.mastery)
          : missingValue;
      },
      cell: (d) => singleUserSymbols[d.value] || toPercentageString(d.value),
    }),
    table.column({
      id: QuestionTableColumn.Accuracy,
      header: "Accuracy",
      accessor: (row) => {
        const result = toResultSet(row);
        const submissions = result.toSubmissions({});
        return submissions.length
          ? toFractionValue(result.accuracy)
          : missingValue;
      },
      cell: (d) => toPercentageString(d.value),
    }),
    table.column({
      id: QuestionTableColumn.Mastery,
      header: "Mastery",
      accessor: (row) => {
        const result = toResultSet(row);
        const submissions = result.toSubmissions({});
        return submissions.length
          ? toFractionValue(result.mastery)
          : missingValue;
      },
      cell: (d) => toPercentageString(d.value),
    }),
  ]);

  const viewModel = table.createViewModel(columns, {
    rowDataId: (item) => item.id,
  });

  const { filterValue } = viewModel.pluginStates.tableFilter;
  const { depth } = viewModel.pluginStates.flatten;
  const { hiddenColumnIds } = viewModel.pluginStates.hide;
  const { sortKeys } = viewModel.pluginStates.sort;
  const { expandedIds } = viewModel.pluginStates.expand;

  let columnToShow: QuestionTableColumn;

  $: dataFilter = writable(state.dataFilter);
  $: $hiddenColumnIds = Object.values(QuestionTableColumn).filter(
    (col) => col !== columnToShow,
  );
  $: $expandedQuestions = state.expandedQuestions;
  $: $showAccuracy = state.showAccuracy;
  $: $showAllQuestions = state.showAllQuestions;
  $: $showTopics = state.showTopics;
  $: $sortKeys = state.sortKeys;
  $: $expandedIds = Object.fromEntries(
    state.expandedRows.map((id) => [id, true]),
  );
  $: $depth = $showTopics ? 0 : 1;

  $: $filterValue = state.tableFilter;
  $: data = filterReportData(reportData, $dataFilter);
  $: columnToShow =
    !$showTopics && data.students.length === 1
      ? QuestionTableColumn.Correct
      : $showAccuracy
        ? QuestionTableColumn.Accuracy
        : QuestionTableColumn.Mastery;

  $: $dataStore = sortTruthy(data.reteyner.topics || []).map(
    (topic, topicIndex) => {
      const children = sortTruthy(topic.questions || []).map((q, index) => ({
        id: q.id,
        index,
        text: q.text,
        question: {
          ...q,
          index,
        },
        tests: data.tests.filter((t) => t.questionId === q.id),
      })).filter(c => $showAllQuestions || c.tests.filter(t => t.submission).length);
      return {
        id: topic.id,
        index: topicIndex,
        text: topic.text,
        tests: flatten(children.map((c) => c.tests)),
        children,
      };
    },
  ).filter(t => $showAllQuestions || t.tests.filter(t => t.submission).length);

  $: $rows = flatten(
    $dataStore.map((topic) =>
      topic.children!.map((question) => ({
        topicNumber: topic.index + 1,
        topic: topic.text,
        questionNumber: question.index + 1,
        question: question.text,
        answersSubmitted: toResultSet(question).toSubmissions({ latest: true })
          .length,
        correctAnswersSubmitted: toResultSet(question).toSubmissions({
          latest: true,
          correctOnly: true,
        }).length,
      })),
    ),
  );

  $: dispatch("update", {
    showTopics: $showTopics,
    showAllQuestions: $showAllQuestions,
    showAccuracy: $showAccuracy,
    sortKeys: $sortKeys,
    dataFilter: $dataFilter,
    expandedQuestions: $expandedQuestions,
    expandedRows: Object.entries($expandedIds)
      .filter(([_, expanded]) => expanded)
      .map(([id]) => id),
    tableFilter: $filterValue,
  });
</script>

{#if $dataFilter.learners.length}
  <div class="flex flex-wrap bg-base-200 gap-3 w-full px-4 py-3 items-center justify-center">
    <ReportDataFilterEditor filter={dataFilter} {data} />
  </div>
{/if}

<div
  class="flex flex-wrap bg-base-200 gap-3 w-full px-4 py-3 items-center justify-between"
>
  <div class="flex flex-wrap gap-3 items-center">
    <div class="form-control">
      <label class="label cursor-pointer flex flex-row gap-3">
        <span class="label-text">Group by topic</span>
        <input type="checkbox" class="toggle" bind:checked={$showTopics} />
      </label>
    </div>
    <div class="form-control">
      <label class="label cursor-pointer flex flex-row gap-3">
        <span class="label-text">Show all questions</span>
        <input type="checkbox" class="toggle" bind:checked={$showAllQuestions} />
      </label>
    </div>
  </div>
  <div class="flex flex-wrap gap-3 items-center grow justify-self-end md:grow-0">
    <div class="form-control grow">
      <label class="input input-bordered input-sm flex items-center gap-3">
        <input
          type="text"
          size="15"
          class="grow"
          placeholder="Search"
          bind:value={$filterValue}
        />
        <div class="w-4 h-4">
          <MagnifyingGlass />
        </div>
      </label>
    </div>
    {#if columnToShow !== QuestionTableColumn.Correct}
      <div class="form-control">
        <ShowAccuracyControl {showAccuracy} />
      </div>
    {/if}
  </div>
  
</div>

<Table {viewModel} />
