<script lang="ts">
  import {
    ReportDataFilter,
    ReportData,
    decodeEmail,
    toLearnerName
  } from "../report-data/index.js";
  import { Cross } from "../reteyn-components/index.js";
  import { Writable } from "svelte/store";

  export let filter: Writable<ReportDataFilter>;
  export let data: ReportData;
</script>

{#each $filter.learners as learner}
  {@const email = decodeEmail(learner)}
  {@const student = data.students.find((s) => s.email === email)}
  {#if student}
    {@const name = toLearnerName(student)}
    <div class="badge badge-lg badge-neutral pr-0 cursor-pointer">
      {name}
      <button
        class="btn btn-ghost btn-circle btn-xs"
        on:click={() => $filter = {
          ...$filter,
          learners: $filter.learners.filter((l) => l !== learner),
        }}
      >
        <Cross />
      </button>
    </div>
  {/if}
{/each}
